const defineLegend = [{
    //图示
    top: 0,
    left: 0,
    itemWidth: 10,
    itemHeight: 10,
    itemGap: 20,
    textStyle: {
      fontSize: "14px",
      color: "#B1B1B1",
    },
    data: [
      {icon: 'roundRect', name: '生产碳排放'},
      {icon: 'roundRect', name: '运输碳排放'},
    ],
  },
  {
    //图示
    top: 0,
    left: '40%',
    itemWidth: 20,
    itemHeight: 10,
    itemGap: 20,
    textStyle: {
      fontSize: "14px",
      color: "#B1B1B1",
    },
    data: [
      {icon: 'path://M904 476H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z', name: '实测碳排放'},
      {icon: 'path://M904 476H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z', name: '预测碳排放'},
    ],
  }
]

const defineSgLegend = [{
    //图示
    top: 0,
    left: 0,
    itemWidth: 10,
    itemHeight: 10,
    itemGap: 20,
    textStyle: {
      fontSize: "14px",
      color: "#B1B1B1",
    },
    data: [
      {icon: 'roundRect', name: '施工区'},
      {icon: 'roundRect', name: '生活区'},
      {icon: 'roundRect', name: '办公区'},
      {icon: 'roundRect', name: '展厅区'},
    ],
  },
  {
    //图示
    top: 0,
    left: '58%',
    itemWidth: 20,
    itemHeight: 10,
    itemGap: 20,
    textStyle: {
      fontSize: "14px",
      color: "#B1B1B1",
    },
    data: [
      {icon: 'path://M904 476H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z', name: '实测碳排放'},
      {icon: 'path://M904 476H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z', name: '预测碳排放'},
    ],
  }
]

const defineCoLegend = [{
    //图示
    top: 0,
    left: 0,
    itemWidth: 10,
    itemHeight: 10,
    itemGap: 20,
    icon: 'roundRect',
    textStyle: {
      fontSize: "14px",
      color: "#B1B1B1",
    },
}]

export {
    defineLegend,
    defineSgLegend,
    defineCoLegend
} 
<template>
    <div class="tab-co2">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部减碳措施" name="4"></el-tab-pane>
        <el-tab-pane label="光伏发电" name="3"></el-tab-pane>
        <el-tab-pane label="绿色建材" name="2"></el-tab-pane>
        <el-tab-pane label="垃圾资源化" name="1"></el-tab-pane>
        <el-tab-pane label="装配式" name="0"></el-tab-pane>
      </el-tabs>
      <div class="co2-age-co2-item">
        <img :src="icon_ystg" />
        <div>{{ barX[activeName] ? barX[activeName] : '累计减碳量' }}</div>
        <div>
          <div>{{ barList[0].data[activeName]?barList[0].data[activeName].value:sumTCo2 }}</div>
          <div>tCO2</div>
        </div>
      </div>
      <zy-chart-bar
        ref="bar"
        :data="barList"
        :axis="barX"
        direction="horizontal"
      ></zy-chart-bar>
    </div>
</template>

<script>
import components from "@/view/jxyh/components"
import icon_ystg from '@/view/jxyh/assets/icons/icon_ystg.png'
export default {
  ...components(["ZyChartBar"]),
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activeName: '4',
      icon_ystg,
      barX: ["装配式", "垃圾资源化", "绿色建材", "光伏发电"],
    }
  },
  computed:{
    sumTCo2(){
      return this.barList[0].data.reduce((a,b) => a + b.value,0) || null
    },
    barList(){
      return [
        {
          data: [
            { value: this.info.prefabricatedCarbonReduction, itemStyle: { color: '#FC4A66' } },
            { value: this.info.wasteRecyclingCarbon, itemStyle: { color: '#FFB822' } },
            { value: this.info.carbonReductionMaterials, itemStyle: { color: '#62FF85' } },
            { value: this.info.carbonReductionGeneration, itemStyle: { color: '#007FF4' } },
          ],
        },
      ]
    }
  },
  methods:{
    handleClick(){
      this.barList[0].data.map((p,index) => {
        if(this.activeName == '4'){
          p.itemStyle.color = p.itemStyle.color.slice(0,7)
          this.$refs.bar.refreshEcharts()
          return
        }
        if(index != Number(this.activeName)){
          p.itemStyle.color = p.itemStyle.color.slice(0,7) + '20';
        }
        else{
          p.itemStyle.color = p.itemStyle.color.slice(0,7)
        }
        this.$refs.bar.refreshEcharts()
      })
    }
  }
}
</script>
<style lang="less" scoped>
.tab-co2{
  /deep/ .el-tabs__item{
    color: #ffffff;
    font-size: 16px;
  }
  /deep/ .el-tabs__item.is-active{
    color: #66BBF9;
  }
  /deep/.el-tabs__nav-wrap::after{
    background: transparent;
  }
  .co2-age-co2-item {
      border: 1px solid rgba(255, 255, 255, 0.1);
      padding: 0 20px 0 0;
      height: 48px;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      & > img {
        width: 48px;
        height: 48px;
      }
      & > div:nth-child(2) {
        font-size: 14px;
        color: #ffffff;
        line-height: 21px;
        width: 100%;
        margin-left: 15px;
        color: #66BBF9;
      }
      & > div:nth-child(3) {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        & > div:nth-child(1) {
          font-weight: bold;
          font-size: 24px;
          color: #ffffff;
        }
        & > div:nth-child(2) {
          font-size: 14px;
          color: #ffffff;
          line-height: 21px;
          margin-left: 10px;
        }
      }
    }
}
</style>
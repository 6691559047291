<template>
  <zy-layout style="color: #ffffff">
    <!-- 左边布局 -->
    <zy-layout-column animation="left">
      <zy-title
        >建材生产与运输阶段碳排放
        <template slot="more">
          <zy-select :data="yearList" v-model="buildYear" @selectType="getPmsEmissionsBuilding"> </zy-select>
        </template>
      </zy-title>
      <zy-chart-bar class="mT10" height="220px" :data="barList" :axis="barX" :color="barColor" :showTitle="true" :defineLegend="defineLegend" :defineTitle="'排放量 tCO2'"></zy-chart-bar>
      <zy-multiple-pie :list="pieList" :color="color"></zy-multiple-pie>
      <zy-title style="margin-top: 40px">施工阶段碳排放
        <template slot="more">
          <zy-select :data="yearList" v-model="phaseYear" @selectType="getConstructionPhase"> </zy-select>
        </template>
      </zy-title>
      <zy-chart-bar class="mT10" height="220px" :data="sgBarList" :axis="barSgX" :color="sgColor" :showTitle="true" :defineLegend="defineSgLegend" :defineTitle="'排放量 tCO2'"></zy-chart-bar>
      <zy-multiple-pie :list="sgList" :legend="legend" :color="color"></zy-multiple-pie>
    </zy-layout-column>
    <!-- 中间布局 -->
    <zy-layout-column style="position: relative" animation="center">
      <zy-title>建筑绿色标准</zy-title>
      <zy-image-text style="margin-top: 14px" :list="jzList"></zy-image-text>
      <zy-title class="jxyh-margin">项目总体碳排放</zy-title>
      <TCO2 :info="totalCarbonInfo"></TCO2>
      <zy-chart-bar style="margin-top: 20px" :height="'280px'" :data="co2List" :axis="barCo2X" :color="barColor" :showTitle="true" :defineTitle="'排放量 tCO2'"></zy-chart-bar>
      <div class="position">
        <zy-select :data="yearList" v-model="totalYear" @selectType="getTotalCarbonEmission"> </zy-select>
      </div>
    </zy-layout-column>
    <!-- 右边布局 -->
    <zy-layout-column animation="right">
      <zy-title>低碳技术应用减碳量</zy-title>
      <zy-chart-bar class="mT10" height="260px" :data="coList" :axis="barCoX" :color="coColor" :defineLegend="defineCoLegend" :labelUnit="'%'"></zy-chart-bar>
      <div class="top-right">
        <zy-select :data="yearList" v-model="contentYear" @selectType="getCarbonContent"> </zy-select>
      </div>
      <tab-co-2 :info="tabCo2Info"></tab-co-2>
      <zy-title class="jxyh-margin">原始数据动态监测</zy-title>
      <pie-table :pieList="totalElectricity.pmsElectricityRegionVOList" style="margin-top: 10px">
        <div style="line-height: 24px; text-align: center; font-size: 12px">
          <div>总用量</div>
          <div style="font-size: 20px">{{ totalElectricity.totalElectricity }}</div>
          <div>kwh</div>
        </div>
      </pie-table>
    </zy-layout-column>
  </zy-layout>
</template>

<script>
import components from '@/view/jxyh/components/index.js'
import TCO2 from './components/tCO2.vue'
import { defineLegend, defineSgLegend, defineCoLegend } from './components/defineLegend.js'
import TabCo2 from './components/tabCo2.vue'
import PieTable from './components/pieTable.vue'
import img_stgl1 from '@/view/jxyh/assets/images/img_stgl1.png'
import img_stgl2 from '@/view/jxyh/assets/images/img_stgl2.png'
import img_stgl3 from '@/view/jxyh/assets/images/img_stgl3.png'
import img_stgl4 from '@/view/jxyh/assets/images/img_stgl4.png'
import { getCarbonContent, getTotalCarbonEmission, getPmsEmissionsBuilding, getConstructionPhase, getTotalElectricity } from '@/jxyh/api/doubleCarbon'
import { getQualityHighLight } from "@/jxyh/api/quality"
export default {
  ...components(['ZyLayout', 'ZyLayoutColumn', 'ZyTitle', 'ZyImageText', 'ZyScrollTable', 'ZyScrollTableColumn', 'ZySelect', 'ZyChartBar', 'ZyChartRing', 'ZyMultiplePie'], {
    TCO2,
    TabCo2,
    PieTable
  }),
  data() {
    return {
      yearList: [
        {
          label: new Date().getFullYear(),
          id: new Date().getFullYear()
        },
        {
          label: new Date().getFullYear() - 1,
          id: new Date().getFullYear() - 1
        },
        {
          label: new Date().getFullYear() - 2,
          id: new Date().getFullYear() - 2
        }
      ],
      buildYear: new Date().getFullYear(),
      phaseYear: new Date().getFullYear(),
      contentYear: new Date().getFullYear(),
      totalYear: new Date().getFullYear(),
      barX: ['24.01', '24.02', '24.03', '24.04', '24.05', '24.06', '24.07', '24.08', '24.09', '24.10', '24.11', '24.12'],
      barList: [
        {
          name: '生产碳排放',
          data: [],
          stack: 'a'
        },
        {
          name: '运输碳排放',
          data: [],
          stack: 'a'
        },
        {
          name: '实测碳排放',
          data: [],
          type: 'line',
          smooth: true,
          showSymbol: false,
        },
        {
          name: '预测碳排放',
          data: [],
          type: 'line',
          smooth: true,
          showSymbol: false
        }
      ],
      barColor: ['#0360F5', '#FFB822', '#66BBF9', '#19FBFF'],
      defineLegend: defineLegend,
      pieList: [
        {
          top: '建材生产',
          value: 0,
          bottom: 'tCO2',
          pieList: []
        },
        {
          top: '建材运输',
          value: 0,
          bottom: 'tCO2',
          pieList: []
        }
      ],
      barSgX:[],
      sgBarList: [
        {
          name: '施工区',
          data: [],
          stack: 'a'
        },
        {
          name: '生活区',
          data: [],
          stack: 'a'
        },
        {
          name: '办公区',
          data: [],
          stack: 'a'
        },
        {
          name: '展厅区',
          data: [],
          stack: 'a'
        },
        {
          name: '实测碳排放',
          data: [],
          type: 'line',
          smooth: true,
          showSymbol: false,
        },
        {
          name: '预测碳排放',
          data: [],
          type: 'line',
          smooth: true,
          showSymbol: false
        }
      ],
      sgList: [
        {
          top: '展厅区',
          value: 0,
          bottom: 'tCO2',
          pieList: []
        },
        {
          top: '办公区',
          value: 0,
          bottom: 'tCO2',
          pieList: []
        },
        {
          top: '生活区',
          value: 0,
          bottom: 'tCO2',
          pieList: []
        },
        {
          top: '施工区',
          value: 0,
          bottom: 'tCO2',
          pieList: []
        }
      ],
      sgColor: ['#0360F5', '#FFB822', '#63E881', '#FC4A66', '#66BBF9', '#19FBFF'],
      defineSgLegend: defineSgLegend,
      legend: ['燃油', '燃气', '电力'],
      color: ['#0360F5', '#66BBF9', '#FFB822'],
      jzList: [
        {
          imgUrl: img_stgl1
        },
        {
          imgUrl: img_stgl2
        },
        {
          imgUrl: img_stgl3
        },
        {
          imgUrl: img_stgl4
        }
      ],
      totalCarbonInfo:{},
      co2List: [
        {
          name: '实测值',
          data: [],
          stack: 'a'
        },
        {
          name: '预测值',
          data: [],
          stack: 'a'
        }
      ],
      barCo2X: [],
      barCoX:[],
      coColor: ['#0360F5', '#63E881', '#FFB822', '#FC4A66'],
      coList: [
        {
          name: '光伏发电',
          data: [],
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          areaStyle: {
            color: {
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#0360F5'
                },
                {
                  offset: 1,
                  color: '#0360F520'
                }
              ]
            }
          },
        },
        {
          name: '绿色建材',
          data: [],
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          areaStyle: {
            color: {
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#63E881'
                },
                {
                  offset: 1,
                  color: '#63E88120'
                }
              ]
            }
          }
        },
        {
          name: '垃圾资源化',
          data: [],
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          areaStyle: {
            color: {
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#FFB822'
                },
                {
                  offset: 1,
                  color: '#FFB82220'
                }
              ]
            }
          }
        },
        {
          name: '装配式',
          data: [],
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          areaStyle: {
            color: {
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#FC4A66'
                },
                {
                  offset: 1,
                  color: '#FC4A6620'
                }
              ]
            }
          }
        }
      ],
      defineCoLegend: defineCoLegend,
      tabCo2Info: {},
      totalElectricity: {},
    }
  },
  mounted() {
    this.getPmsEmissionsBuilding()
    this.getConstructionPhase()
    this.getCarbonContent()
    this.getTotalCarbonEmission()
    this.getTotalElectricity()
    this.getImageText()
  },
  methods: {
    getPmsEmissionsBuilding() {
      getPmsEmissionsBuilding({duration:this.buildYear}).then((res) => {
        this.barX = res.data.x
        this.barList[0].data = res.data.productionY
        this.barList[1].data = res.data.transportY
        this.barList[2].data = res.data.measuredY
        this.barList[3].data = res.data.predictingY
        this.pieList[0].value = res.data.measuredTotal
        this.pieList[0].pieList = res.data.idLabelList
        this.pieList[1].value = res.data.travelMeasuredTotal
        this.pieList[1].pieList = res.data.idLabelListMaterials
      })
    },
    getConstructionPhase() {
      getConstructionPhase({duration:this.phaseYear}).then((res) => {
        this.barSgX = res.data.x
        this.sgBarList[0].data = res.data.eleMeasuredConstruction
        this.sgBarList[1].data = res.data.eleMeasuredLiving
        this.sgBarList[2].data = res.data.eleMeasuredOffice
        this.sgBarList[3].data = res.data.eleMeasuredExhibition
        this.sgBarList[4].data = res.data.measuredY
        this.sgBarList[5].data = res.data.predictingY
        this.sgList[0].value = res.data.idLabelConstructionTotal
        this.sgList[0].pieList = res.data.idLabelConstruction
        this.sgList[1].value = res.data.idLabelLivingTotal
        this.sgList[1].pieList = res.data.idLabelLiving
        this.sgList[2].value = res.data.idLabelOfficeTotal
        this.sgList[2].pieList = res.data.idLabelOffice
        this.sgList[3].value = res.data.idLabelExhibitionTotal
        this.sgList[3].pieList = res.data.idLabelExhibition
      })
    },
    getCarbonContent() {
      getCarbonContent({year:this.contentYear}).then((res) => {
        this.barCoX = res.data.pmsCurveCarbonVOList.map(p => p.monthYear)
        this.coList[0].data = res.data.pmsCurveCarbonVOList.map(p => p.carbonReductionGeneration)
        this.coList[1].data = res.data.pmsCurveCarbonVOList.map(p => p.carbonReductionMaterials)
        this.coList[2].data = res.data.pmsCurveCarbonVOList.map(p => p.wasteRecyclingCarbon)
        this.coList[3].data = res.data.pmsCurveCarbonVOList.map(p => p.prefabricatedCarbonReduction)
        this.tabCo2Info = res.data
      })
    },
    getTotalCarbonEmission() {
      getTotalCarbonEmission({year:this.totalYear}).then((res) => {
        this.totalCarbonInfo = res.data
        this.co2List[0].data = res.data.pmsCylindricalityCarbonVOList.map(p => p.measuredValue)
        this.co2List[1].data = res.data.pmsCylindricalityCarbonVOList.map(p => p.predictedValue)
        this.barCo2X = res.data.pmsCylindricalityCarbonVOList.map(p => p.yearMonth)
      })
    },
    getTotalElectricity(){
      getTotalElectricity().then((res) => {
        this.totalElectricity = res.data
      })
    },
    getImageText(){
      getQualityHighLight({ viewEnum: 7 }).then((res) => {
        this.jzList = res.data || []
      })
    }
  }
}
</script>

<style lang="less" scoped>
.mT10 {
  margin-top: 10px;
}
.position {
  position: absolute;
  right: 0;
  bottom: 280px;
  width: 120px;
}
.top-right {
  position: absolute;
  right: 20px;
  top: 156px;
  width: 120px;
}
</style>

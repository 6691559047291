import { get, post } from "@/utils/request"

// 低碳技术应用减碳量
export function getCarbonContent(params) {
  return get("/pms/view/dualCarbon/carbonContent", params)
}

// 项目总体碳排放
export function getTotalCarbonEmission(params) {
  return get("/pms/view/dualCarbon/totalCarbonEmission", params)
}

// 建材生产与运输阶段碳排放
export function getPmsEmissionsBuilding(params) {
  return get("/pms/view/doubleCarbon/pmsEmissionsBuilding", params)
}

// 施工阶段碳排放
export function getConstructionPhase(params) {
  return get("/pms/view/doubleCarbon/constructionPhase", params)
}

// 原始数据动态监测
export function getTotalElectricity(params) {
  return get("/pms/view/dualCarbon/totalElectricity", params)
}


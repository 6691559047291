<template>
  <div class="co2-age">
    <div class="co2-age-co2">
      <div class="co2-age-co2-item">
        <img :src="icon_ystg" />
        <div>实际碳排放</div>
        <div>
          <div>{{ info.totalMeasuredValue }}</div>
          <div>tCO2</div>
        </div>
      </div>
      <div class="co2-age-co2-item">
        <img :src="icon_yctpf" />
        <div style="color: #FFB822;">预测碳排放</div>
        <div>
          <div>{{ info.totalPredictedValue }}</div>
          <div>tCO2</div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import icon_ystg from '@/view/jxyh/assets/icons/icon_ystg.png'
import icon_yctpf from '@/view/jxyh/assets/icons/icon_yctpf.png'
export default {
  props:{
    info:{
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    console.log(this.info);
  },
  data() {
    return {
      icon_ystg,
      icon_yctpf
    }
  }
}
</script>
  
  <style lang="less" scoped>
.co2-age {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  &-title {
    padding: 10px 0 20px 0;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    line-height: 24px;
  }
  &-co2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    &-item {
      border: 1px solid rgba(255, 255, 255, 0.1);
      padding: 0 20px 0 0;
      height: 48px;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      &:last-child{
        margin-left: 12px;
      }
      & > img {
        width: 48px;
        height: 48px;
      }
      & > div:nth-child(2) {
        font-size: 14px;
        color: #ffffff;
        line-height: 21px;
        width: 100%;
        margin-left: 15px;
        color: #66BBF9;
      }
      & > div:nth-child(3) {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        & > div:nth-child(1) {
          font-weight: bold;
          font-size: 24px;
          color: #ffffff;
        }
        & > div:nth-child(2) {
          font-size: 14px;
          color: #ffffff;
          line-height: 21px;
          margin-left: 10px;
        }
      }
    }
    & + & {
      margin-top: 10px;
    }
  }
}
</style>
  
<template>
  <div class="zy-pie-table">
    <div class="zy-pie-table-chart">
      <div class="zy-pie-table-chart-pie">
        <zy-chart-ring :color="color" :data="pieList">
          <slot></slot>
        </zy-chart-ring>
      </div>
      <div class="zy-pie-table-chart-tips">
        <div class="zy-pie-table-chart-tips-item" v-for="(item, index) in pieList" :key="`tips_${index}`">
          <div :style="{ background: color[index] }"></div>
          <div>{{ item.name.replace('用电','') }}</div>
        </div>
      </div>
    </div>
    <div class="zy-pie-table-list">
      <zy-scroll-table :data="pieList">
        <zy-scroll-table-column label="区域" width="100px" prop="name">
            <template slot-scope="scope">{{ scope.row.name }}</template>
        </zy-scroll-table-column>
        <zy-scroll-table-column align="right" label="今日用量(kwh)" width="110px" prop="todayDosage"></zy-scroll-table-column>
        <zy-scroll-table-column align="right" label="总用量(kwh)" width="120px" prop="value"></zy-scroll-table-column>
      </zy-scroll-table>
    </div>
  </div>
</template>
  
  <script>
import components from '@/view/jxyh/components'
export default {
  ...components(['ZyChartRing', 'ZyScrollTable', 'ZyScrollTableColumn'], {}),
  data() {
    return {
    }
  },
  props: {
    color: {
      type: Array,
      default: () => ['#007FF4', '#66BBF9', '#FFB822', '#62FF85']
    },
    pieList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    headerCellStyle() {
      return {
        background: '#1E5087',
        borderBottom: 'unset',
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#FFFFFF',
        lineHeight: '21px',
        padding: '12px 0 14px 0'
      }
    },
    headerRowStyle() {
      return {
        background: '#1E5087'
      }
    },
    rowStyle() {
      return {
        background: '#1E5087'
      }
    },
    cellStyle() {
      return {
        fontSize: '14px',
        color: '#FFFFFF',
        lineHeight: '21px',
        background: '#1E5087',
        borderBottom: 'rgba(255, 255, 255, 0.1) 1px solid'
      }
    }
  }
}
</script>
  
  <style lang="less" scoped>
.zy-pie-table {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  &-chart {
    display: flex;
    flex-direction: column;
    &-pie {
      width: 160px;
      height: 160px;
    }
    &-tips {
      width: 160px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: 10px;
      &-item {
        margin-top: 16px;
        font-size: 12px;
        color: #ffffff;
        line-height: 18px;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;
        & > div:nth-child(1) {
          width: 10px;
          height: 10px;
          margin-right: 6px;
        }
      }
    }
  }
  &-list {
    width: 324px;
  }
}
.table {
  &-tips {
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
  &-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.el-table {
  background: transparent;
}
</style>
  